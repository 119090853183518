table.t1 th,
      table.t1 td {
        text-transform: none;
        font-family: "Avenir LT W01 55 Roman";
        font-size: 18px;
        line-height: 1.5;
      }
      table.t1 th {
        background: #2a6293;
        color: #fff;
      }
      table.t1 tbody tr:nth-child(odd) {
        background: #efefef;
      }
      table.t1 tbody tr:nth-child(even) {
        background: #fff;
      }
      table.t1 td a {
        /* darken link text in shaded table rows for contrast */
        color: #2b6fa6;
      }
      a[href^="tel:"] {
        color: #165c7e;
        text-decoration: none;
        font-weight: bold;
      }
      .toggle,
      .toggle2 {
        float: right;
        position: relative;
        top: -38px;
        text-decoration: underline;
        margin-top: -10px;
        height: 0;
        font-weight: bold;
      }
      @media (min-width: 1280px) {
        .toggle {
          margin-right: 300px;
        }
        .toggle2 {
          margin-right: 0;
        }
      }
      .flex-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
      }
      .flex-container > div {
        background-color: #fff;
        border: 1px solid #bbd7d3;
        width: 270px;
        text-align: center;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
        margin: 10px 10px 20px;
        padding: 18px;
      }
      .flex-container > div p {
        text-align: left;
      }
      .flex-container > div img {
        width: initial;
      }
      div.flex-container ul {
        text-align: left;
        margin-left: 0px;
      }
      /* begin 2 column CSS */
      article.clayout {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1em;
      }
      @media only screen and (max-width: 700px) {
        article.clayout {
          display: grid;
          grid-template-columns: 1fr;
          grid-column-gap: 0;
        }
      }
      div.clayout {
        display: flex;
        flex-flow: row wrap;
      }
      article.callout {
        /* shaded full width content below 2-col layout */
        background: #f7f7f7;
        padding: 0 2rem;
        margin-top: 10px;
      }
      /* contact boxes custom flexbox variation */
      .contactbox {
        margin: 10px 0 0;
        padding: 18px 0 0;
      }
      .contactbox div {
        height: 225px;
        margin-left: 0;
        margin-right: 50px;
      }
      /* sidebar menu - darken text and change background color */
      .side-section .nav > li.active > a,
      .side-section .nav > li > a:hover {
        color: #ad4e00;
        background: #fafbfc;
      }
      a.linkunder {
        /* underline links with this class */
        text-decoration: underline;
      }
      #block-npamedicare-footer a,
      .region-footer a {
        /*darken text of footer links for accessibility */
        color: #2a6293;
      }