@font-face {
  font-family: "Avenir LT W01 45 Book";
  src: url(../fonts/4f32268f-fd86-4960-b72c-4bb1ba75ec6f.eot?#iefix);
  src: url(../fonts/4f32268f-fd86-4960-b72c-4bb1ba75ec6f.eot?#iefix)
      format("eot"),
    url(../fonts/939cba03-5b40-4d01-9bc5-7589eca863db.woff) format("woff"),
    url(../fonts/849bc5b9-a2ff-4343-977b-26ba8bd24a60.ttf) format("truetype"),
    url(../fonts/f67fa3b5-c1d1-488f-8e60-a828b9ad56a4.svg#f67fa3b5-c1d1-488f-8e60-a828b9ad56a4)
      format("svg");
}
@font-face {
  font-family: "AvenirLTW01-45BookObliq";
  src: url(../fonts/acc13cdc-338a-43e6-a156-e54a4c87582d.eot?#iefix);
  src: url(../fonts/acc13cdc-338a-43e6-a156-e54a4c87582d.eot?#iefix)
      format("eot"),
    url(../fonts/ca94ed56-18fc-4856-940a-70a072607c7c.woff) format("woff"),
    url(../fonts/dd6da407-70fe-4aa3-a1c7-64f0cb086f01.ttf) format("truetype"),
    url(../fonts/c7f424be-90e1-45b8-b617-ee3589a859bd.svg#c7f424be-90e1-45b8-b617-ee3589a859bd)
      format("svg");
}
@font-face {
  font-family: "Avenir LT W01 35 Light";
  src: url(../fonts/3511a780-31ca-4ee1-9083-89b1b7185748.eot?#iefix);
  src: url(../fonts/3511a780-31ca-4ee1-9083-89b1b7185748.eot?#iefix)
      format("eot"),
    url(../fonts/c4352a95-7a41-48c1-83ce-d8ffd2a3b118.woff) format("woff"),
    url(../fonts/3e419b5b-c789-488d-84cf-a64009cc308e.ttf) format("truetype"),
    url(../fonts/ca038835-1be3-4dc5-ba25-be1df6121499.svg#ca038835-1be3-4dc5-ba25-be1df6121499)
      format("svg");
}
@font-face {
  font-family: "AvenirLTW01-55Oblique";
  src: url(../fonts/8f21e618-9282-4df1-b556-73ee82bdd673.eot?#iefix);
  src: url(../fonts/8f21e618-9282-4df1-b556-73ee82bdd673.eot?#iefix)
      format("eot"),
    url(../fonts/3695342c-b5e2-4010-b0d4-56f563465922.woff) format("woff"),
    url(../fonts/2ba9f875-66d9-414d-a426-5a012b443475.ttf) format("truetype"),
    url(../fonts/0a88351a-e628-4b1f-99eb-3a729518af0a.svg#0a88351a-e628-4b1f-99eb-3a729518af0a)
      format("svg");
}
@font-face {
  font-family: "AvenirLTW01-35LightObli";
  src: url(../fonts/6500273c-8e8b-48d2-b0c0-dcae293c0120.eot?#iefix);
  src: url(../fonts/6500273c-8e8b-48d2-b0c0-dcae293c0120.eot?#iefix)
      format("eot"),
    url(../fonts/47fd1045-6e5b-4c87-9235-cfbc2b42cde7.woff) format("woff"),
    url(../fonts/60662abb-0ac3-46b2-930f-4719462489c9.ttf) format("truetype"),
    url(../fonts/9cf39ac2-87a5-4c4e-a604-7cece19d30bd.svg#9cf39ac2-87a5-4c4e-a604-7cece19d30bd)
      format("svg");
}
@font-face {
  font-family: "Avenir LT W01 85 Heavy";
  src: url(../fonts/f61bf0f6-c193-4100-b940-12a1a01dcee5.eot?#iefix);
  src: url(../fonts/f61bf0f6-c193-4100-b940-12a1a01dcee5.eot?#iefix)
      format("eot"),
    url(../fonts/7147ec3a-8ff8-4ec9-8c5c-bd1571dc6ae6.woff) format("woff"),
    url(../fonts/d1dc54b2-878d-4693-8d6e-b442e99fef68.ttf) format("truetype"),
    url(../fonts/731dd4d3-64da-427d-ba61-01575b3cf3f7.svg#731dd4d3-64da-427d-ba61-01575b3cf3f7)
      format("svg");
}
@font-face {
  font-family: "AvenirLTW01-65MediumObl";
  src: url(../fonts/2a6f81a2-475c-4831-9949-33d7748ee561.eot?#iefix);
  src: url(../fonts/2a6f81a2-475c-4831-9949-33d7748ee561.eot?#iefix)
      format("eot"),
    url(../fonts/e0af2f49-a399-482b-a54e-d745e268ec80.woff) format("woff"),
    url(../fonts/6803d3dd-2107-45fc-ac8a-a4fa13771547.ttf) format("truetype"),
    url(../fonts/1bb1acb5-8d4e-4800-88af-dbcf1fe96ef8.svg#1bb1acb5-8d4e-4800-88af-dbcf1fe96ef8)
      format("svg");
}
@font-face {
  font-family: "Avenir LT W01 55 Roman";
  src: url(../fonts/2f5a6074-badc-4e08-83f1-ed67fe5c3d7c.eot?#iefix);
  src: url(../fonts/2f5a6074-badc-4e08-83f1-ed67fe5c3d7c.eot?#iefix)
      format("eot"),
    url(../fonts/b9ebb19d-88c1-4cbd-9baf-cf51972422ec.woff) format("woff"),
    url(../fonts/5ed4f98e-9044-4810-88ff-74d412c1351f.ttf) format("truetype"),
    url(../fonts/4cb16859-16ca-4342-b89c-292dc83266af.svg#4cb16859-16ca-4342-b89c-292dc83266af)
      format("svg");
}
@font-face {
  font-family: "Avenir LT W01 95 Black";
  src: url(../fonts/a24b3f55-c8c8-41a1-a77e-7cdf417d82f6.eot?#iefix);
  src: url(../fonts/a24b3f55-c8c8-41a1-a77e-7cdf417d82f6.eot?#iefix)
      format("eot"),
    url(../fonts/5a05cf7f-f332-456f-ae67-d8c26e0d35b3.woff) format("woff"),
    url(../fonts/c33278bc-988e-440b-b4be-6acc095e6044.ttf) format("truetype"),
    url(../fonts/7bdad4a5-289b-42f9-b6fa-c5883b9e9f23.svg#7bdad4a5-289b-42f9-b6fa-c5883b9e9f23)
      format("svg");
}
@font-face {
  font-family: "AvenirLTW01-95BlackObli";
  src: url(../fonts/fbf3e69b-73b2-406d-84bc-feda30a0a563.eot?#iefix);
  src: url(../fonts/fbf3e69b-73b2-406d-84bc-feda30a0a563.eot?#iefix)
      format("eot"),
    url(../fonts/36ec15b9-f8da-447f-8fed-a9cfdfb91fbb.woff) format("woff"),
    url(../fonts/230d6b14-6d44-4dd5-a6ae-a4287936e51e.ttf) format("truetype"),
    url(../fonts/9bce4920-94e2-4e4d-bd34-818801dd3eb3.svg#9bce4920-94e2-4e4d-bd34-818801dd3eb3)
      format("svg");
}
@font-face {
  font-family: "Avenir LT W01 65 Medium";
  src: url(../fonts/212ab03d-5db2-4d6a-b94c-171cc702aa51.eot?#iefix);
  src: url(../fonts/212ab03d-5db2-4d6a-b94c-171cc702aa51.eot?#iefix)
      format("eot"),
    url(../fonts/2cac77ec-9bc0-4ee7-87e4-27650190744f.woff) format("woff"),
    url(../fonts/aaf11848-aac2-4d09-9a9c-aac5ff7b8ff4.ttf) format("truetype"),
    url(../fonts/294099f3-8d00-4676-afc5-5e9d8d43ad69.svg#294099f3-8d00-4676-afc5-5e9d8d43ad69)
      format("svg");
}
@font-face {
  font-family: "AvenirLTW01-85HeavyObli";
  src: url(../fonts/7be0fdec-384a-42b5-ab27-c60a322cde7d.eot?#iefix);
  src: url(../fonts/7be0fdec-384a-42b5-ab27-c60a322cde7d.eot?#iefix)
      format("eot"),
    url(../fonts/53554f6f-4b01-4815-87fe-82c49b8ba5a9.woff) format("woff"),
    url(../fonts/28dfedaf-6372-45a1-8833-b3078dc56a21.ttf) format("truetype"),
    url(../fonts/f747b6ee-4d13-4e45-815d-f33ac1b71d63.svg#f747b6ee-4d13-4e45-815d-f33ac1b71d63)
      format("svg");
}
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Avenir LT W01 55 Roman";
  font-size: 16px;
  line-height: 1.42857143;
  color: #000;
  background-color: #fff;
}
.ui-widget {
  font-family: "Avenir LT W01 55 Roman";
}
body.big {
  font-size: 20px;
}
body.bigger {
  font-size: 24px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Avenir LT W01 85 Heavy";
  line-height: 1.4;
}
h1,
.h1 {
  font-size: 2em;
}
h2,
.h2 {
  font-size: 1.647em;
}
h3,
.h3 {
  font-size: 1.176em;
}
h4,
.h4 {
  font-size: 1em;
}
h5,
.h5 {
  font-size: 1em;
}
h6,
.h6 {
  font-size: 1em;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
/*common-classes*/
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.d-inline-block {
  display: inline-block;
}
.w-25 {
  width: 25%;
}
.w-50 {
  width: 50%;
}
.w-75 {
  width: 75%;
}
.w-100 {
  width: 100%;
}
.h-25 {
  height: 25%;
}
.h-50 {
  height: 50%;
}
.h-75 {
  height: 75%;
}
.h-100 {
  height: 100%;
}
.m-0 {
  margin: 0;
}
.mt-0,
.my-0 {
  margin-top: 0;
}
.mr-0,
.mx-0 {
  margin-right: 0;
}
.mb-0,
.my-0 {
  margin-bottom: 0;
}
.ml-0,
.mx-0 {
  margin-left: 0;
}
.m-1 {
  margin: 0.25rem;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem;
}
.m-3 {
  margin: 1rem;
}
.mt-3,
.my-3 {
  margin-top: 1rem;
}
.mr-3,
.mx-3 {
  margin-right: 1rem;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem;
}
.ml-3,
.mx-3 {
  margin-left: 1rem;
}
.m-4 {
  margin: 1.5rem;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem;
}
.m-5 {
  margin: 3rem;
}
.mt-5,
.my-5 {
  margin-top: 3rem;
}
.mr-5,
.mx-5 {
  margin-right: 3rem;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem;
}
.ml-5,
.mx-5 {
  margin-left: 3rem;
}
.p-0 {
  padding: 0;
}
.pt-0,
.py-0 {
  padding-top: 0;
}
.pr-0,
.px-0 {
  padding-right: 0;
}
.pb-0,
.py-0 {
  padding-bottom: 0;
}
.pl-0,
.px-0 {
  padding-left: 0;
}
.p-1 {
  padding: 0.25rem;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem;
}
.p-3 {
  padding: 1rem;
}
.pt-3,
.py-3 {
  padding-top: 1rem;
}
.pr-3,
.px-3 {
  padding-right: 1rem;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem;
}
.pl-3,
.px-3 {
  padding-left: 1rem;
}
.p-4 {
  padding: 1.5rem;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem;
}
.p-5 {
  padding: 3rem;
}
.pt-5,
.py-5 {
  padding-top: 3rem;
}
.pr-5,
.px-5 {
  padding-right: 3rem;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem;
}
.pl-5,
.px-5 {
  padding-left: 3rem;
}
.m-auto {
  margin: auto;
}
.mt-auto,
.my-auto {
  margin-top: auto;
}
.mr-auto,
.mx-auto {
  margin-right: auto;
}
.mb-auto,
.my-auto {
  margin-bottom: auto;
}
.ml-auto,
.mx-auto {
  margin-left: auto;
}
.font-weight-light {
  font-weight: 300;
}
.font-weight-normal {
  font-weight: 400;
}
.font-weight-bold {
  font-weight: 700;
}
.border-0 {
  border: 0;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.radius {
  border-radius: 0;
}
.radius-4 {
  border-radius: 4px;
}
.radius-20 {
  border-radius: 20px;
}
.blue-color {
  color: #3b64b8;
}
.black-color {
  color: #000 !important;
}
.orange {
  color: #f17814;
}
.white-color {
  color: #fff;
}
.line-4 {
  line-height: 1.4;
}
.line-height {
  line-height: 1.8;
}
.text-center {
  text-align: center;
}
.text-uppercase {
  text-transform: uppercase;
}
.d-block {
  display: block;
}
.bg-gray {
  background-color: #f3f3f3;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.sm-shadow {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
}
.font-mont {
  font-family: "Montserrat", sans-serif;
}
.text-left {
  text-align: left;
}
/*navigation-section*/
.npa.navbar-default {
  background-color: #d5e4e8;
  border-top: 1px solid #fff;
  border-radius: 0;
  margin-bottom: 0;
  width: 100%;
}
/*.npa.navbar-default.npa{
     background: #ffffff;
     background: -moz-linear-gradient(top, #ffffff 0%, #fbfbfb 100%);
     background: -webkit-linear-gradient(top, #ffffff 0%,#fbfbfb 100%);
     background: linear-gradient(to bottom, #ffffff 0%,#fbfbfb 100%);
     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#fbfbfb',GradientType=0 );
}
*/
.npa.navbar-default #navbar-collapse {
  width: 100%;
}
/*banner-section*/
.slider-area {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  height: 300px;
  overflow: hidden;
}
.slider-content {
  padding-top: 80px;
  padding-bottom: 80px;
}
.slide {
  position: absolute;
  width: 100%;
}
.slider-content {
  position: relative;
  z-index: 11;
}
.navbar-brand {
  padding-left: 0;
  padding-right: 0;
}
.column-area {
  float: left;
  width: 100%;
}
.cOuter .field--name-body {
  background-color: #fff;
  border: 2px solid #000;
  position: relative;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
  height: 100%;
  padding: 1rem;
}
.contact-area {
  border-top: 1px solid #d8d8d8;
}
.bottom-links {
  background-color: #eef4f6;
  text-align: center;
}
.bottom-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.bottom-links ul li {
  display: inline-block;
  vertical-align: middle;
}
.bottom-links ul li a {
  display: block;
  padding: 5px 20px;
  font-size: 0.9444444444444444em;
}
.search-section {
  float: right;
  width: 200px;
}
.tab-area {
  float: right;
}
.tab-area ul {
  list-style: none;
  margin-bottom: 0;
}
.tab-area ul li {
  float: left;
}
.tab-area ul li:first-child a {
  line-height: 23px;
  font-size: 11px;
  border-radius: 3px 0px 0px 3px;
}
.tab-area ul li:last-child a {
  border-radius: 0px 3px 3px 0px;
}
.tab-area ul li a {
  border: 1px solid #cdcccc;
  border-radius: 0;
  height: 34px;
  color: #000;
  background-color: #fff;
  display: block;
  padding: 6px 12px;
  font-size: 16px;
}
.tab-area ul li:first-child a {
  line-height: 21px;
  font-size: 11px;
}
.tab-area ul li:nth-child(2) a {
  border-right: 0;
  border-left: 0;
  font-size: 14px;
  line-height: 1.6;
}
.tab-area ul li a.bigger {
  font-size: 16px;
}
.area-search {
  background-color: #b0ddff;
  color: #000;
  box-shadow: 140px 0px 0px #b0ddff;
  height: 50px;
}
.fixed-top {
  position: fixed !important;
  top: 0px;
  right: 0;
  left: 0;
  z-index: 10000 !important;
  background-color: #fff;
  transition: all 0.5s ease-in 0s;
}
span.search-state {
  display: block;
}
span.search-state input {
  border-radius: 0;
  border: none;
  background-color: #f2f2f2;
}
.area-search.btn-info:active,
.area-search.btn-info.active,
.area-search.btn-info:hover,
.area-search.btn-info:focus {
  background-color: #b0ddff;
  border-color: #b0ddff;
  outline-offset: 0px;
  outline: none;
  color: #000;
}
/*plan-page*/
.plan-section {
  background-color: #445565;
}
.plan-section-bg {
  background-color: #3b64b8;
  display: inline-block;
}
.btn-orange {
  color: #fff;
  background: #f2842f;
  background: -moz-linear-gradient(top, #f2842f 0%, #e2461c 100%);
  background: -webkit-linear-gradient(top, #f2842f 0%, #e2461c 100%);
  background: linear-gradient(to bottom, #f2842f 0%, #e2461c 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2842f', endColorstr='#e2461c',GradientType=0 );
  border: 1px solid #ff8716;
}
.btn-orange:hover,
.btn-orange:focus {
  text-shadow: 0px 1px 2px rgba(1, 1, 1, 0.75);
  color: #fff;
  text-decoration: underline;
}
.side-section .navbar-nav {
  background-color: #eef4f6;
  border: 1px solid #d7e8f7;
  width: 100%;
  margin-bottom: 30px;
}
.side-section .navbar-nav li {
  background-color: transparent;
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
}
.side-section .navbar-nav li:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: none;
}
.side-section .navbar-nav li a {
  color: #000;
}
.side-section .navbar-nav li:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom: none;
}
.side-section .navbar-nav li ul {
  position: relative;
  min-width: inherit;
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  border: 0;
  background-color: transparent;
}

.side-section .navbar-nav li a span.caret {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 40px;
  height: 100%;
  border: 1px solid transparent;
  text-align: center;
  align-items: center;
  display: flex;
}
.side-section .navbar-nav li a span.caret:before {
  content: "";
  border-top: 4px dashed;
  display: inline-block;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  display: inline-block;
  position: relative;
  margin: 0 auto;
}
.side-section .navbar-nav li ul li {
  border-bottom: none;
}
.side-section .navbar-nav li ul li a {
  white-space: normal;
  word-break: break-word;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #00749f;
  font-size: 16px;
}
.border-black {
  border: 1px solid #000;
}
.npa-gradient {
  background: rgb(251, 252, 252);
  background: -moz-linear-gradient(
    left,
    rgba(251, 252, 252, 1) 0%,
    rgba(222, 235, 234, 1) 47%,
    rgba(251, 252, 252, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(251, 252, 252, 1) 0%,
    rgba(222, 235, 234, 1) 47%,
    rgba(251, 252, 252, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(251, 252, 252, 1) 0%,
    rgba(222, 235, 234, 1) 47%,
    rgba(251, 252, 252, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbfcfc', endColorstr='#fbfcfc',GradientType=1 );
}
.npa.navbar-default ul li a,
.npa.navbar-default ul li span.cus-documents {
  color: #0070c0;
  white-space: normal;
}

.npa.navbar-default ul li span.cus-documents {
  cursor: default;
}

.npa.navbar-default ul li a,
.npa.navbar-default ul li span.cus-documents span {
  cursor: pointer;
}

.npa.navbar-default ul li span.cus-documents span {
  width: 20px;
  height: 20px;
  border: 1px solid transparent;
}

.npa.navbar-default ul li span.cus-documents span:before {
  content: "";
  border-top: 4px dashed;
  display: inline-block;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  display: inline-block;
  position: relative;
  margin: 0 auto;
  top: -8px;
}

.npa.navbar-default ul li.active span.cus-documents {
  color: #fff;
}
.npa.navbar-default ul li span.cus-documents {
  display: block;
}
.navbar {
  z-index: 99;
}
.npa.navbar-default ul li:hover > a,
.navbar-default .navbar-nav > .active > a,
.npa.navbar-default ul li:hover span.cus-documents,
.navbar-nav > .active > span.cus-documents {
  color: #fff;
  background-color: #055a8d;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-nav > .active > span.cus-documents,
.navbar-nav > .active > span.cus-documents:hover,
.navbar-nav > .active > span.cus-documents:focus {
  color: #fff;
  background-color: #055a8d;
}
.navbar-default .navbar-nav > .active ul li.active a {
  color: #ec6c00;
}
.navbar-default .navbar-nav > .active ul li.active:hover a {
  color: #fff;
}
.navbar-default .navbar-nav > .active ul li.active:hover ul li a {
  color: #0070c0;
}
.navbar-default .navbar-nav > .active ul li.active:hover ul li:hover a {
  color: #fff;
}
.btn-warning.enroll-bt {
  background-color: #f17813;
}
.row-divider {
  border-right: 1px solid #d8d8d8;
}
.contact-section {
  background-color: #fff;
  border: 1px solid #bbd7d3;
  float: left;
  width: 100%;
}
.contact-section p a.contactUs {
  color: #f59d56;
}
.enroll {
  background: rgb(11, 154, 199);
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(11, 154, 199, 1) 0%,
    rgba(26, 136, 172, 1) 43%,
    rgba(30, 130, 175, 1) 100%
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(11, 154, 199, 1) 0%,
    rgba(26, 136, 172, 1) 43%,
    rgba(30, 130, 175, 1) 100%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(11, 154, 199, 1) 0%,
    rgba(26, 136, 172, 1) 43%,
    rgba(30, 130, 175, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0b9ac7', endColorstr='#1e82af',GradientType=1 );
  display: block;
  clear: both;
  float: left;
  width: 100%;
  text-align: center;
}
.enroll h3 {
  margin-top: 0;
  color: #fff;
}
.enroll ul {
  list-style: none;
  text-align: center;
  padding-left: 0;
}
.enroll ul li {
  display: inline-block;
}
.enroll ul li img {
  margin: 0 0px 10px;
  width: 34px;
}
.enroll p {
  margin-bottom: 0;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.cross-sign.modal-header {
  right: 0;
  top: 0;
}
.search-field {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.12) inset;
  position: relative;
}
.search-field .caret {
  right: 8px;
  top: 14px;
}
.go-btn {
  color: #fff;
  background-color: #f17813;
  border: 2px solid #f17813;
}
.modal {
  text-align: center;
  padding: 0 !important;
}
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.state-section {
  height: 50px;
  background-color: #b0ddff;
  padding: 7px 0px 7px 15px;
  box-shadow: 81px 0px 0px 0px #b0ddff;
  display: none;
}
.state-section p {
  margin-bottom: 0;
}
.show-state.btn.btn-orange:hover {
  color: #fff;
}
.navbar-default .navbar-nav > li > a {
  position: relative;
}
.btn.icon-only {
  color: #3b64b8;
  background-color: #fff;
  border: 1px solid #ccc;
  border-left: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.modal-backdrop.fade.in {
  display: none;
}
.modal.fade.in {
  background-color: rgba(0, 0, 0, 0.5);
}
.field.field--name-field-table-title {
  display: none;
}
ul.list-area {
  list-style: none;
}
ul.list-area li {
  position: relative;
  padding: 0px 0px 0px 18px;
}
ul.list-area li:before {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  content: "";
  left: 0;
  top: 6px;
  background-color: #000;
}
.state-modal .input-group-addon {
  display: none;
}
.place-section .input-group {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  color: #000;
  outline: none;
}
.place-section .input-group .form-control {
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 40px;
  padding: 12px 12px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.12) inset;
}
.place-section .form-group {
  margin-bottom: 0px;
}
.change-state-content-form {
  position: relative;
  z-index: 1;
}
.field__label {
  display: none;
}
/* .home-content h1{
     color: #F17814;
     font-size: 30px;
     border:none;
     margin-top: 0;
}
 .home-content .field__item > a{
     display: none;
}
 .home-content .field__item p, .home-content .field__item ul li{
     font-weight: 400;
     line-height: 1.4;
     margin-top: 10px;
     margin-bottom: 10px;
}
*/
.slider-area .slider-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.slider-area .slider-image img {
  width: 100%;
  object-fit: cover;
  object-position: top;
}
.slider-area .slider-image .field.field--name-body {
  height: 100%;
}
.slider-area .slider-image .field.field--name-body .slide-pic {
  height: 100%;
}
.slider-area .slider-image img {
  width: 100%;
}
.slider-area .container .region-slider {
  width: 100%;
}
.slider-area .container .region-slider .contextual-region {
  position: static;
}
.slider-area .container .region-slider .form-group {
  margin-bottom: 0px;
  overflow: hidden;
}
.slider-area .container .region-slider .form-group .contextual-region {
  position: static;
}
.slider-area .slider-image h1,
.slider-area .slider-image h3 {
  display: none;
}
.slider-area .view-content {
  width: 100%;
}
.slider-area .view-content .views-row {
  width: 100%;
}
.internal-page h1 {
  margin-top: 10px;
  font-weight: 500;
  font-size: 1.33em;
  line-height: 1.3;
  color: #055a8d;
}
body.big .internal-page h1 {
  font-size: 1.3em;
  line-height: 1.3;
}
body.bigger .internal-page h1 {
  font-size: 1.2727272727272727em;
  line-height: 1.4;
}
.internal-page h2 {
  font-size: 1.2222222222222223em;
}
body.big .internal-page h2 {
  font-size: 1.2222222222222223em;
  line-height: 1.2;
}
body.bigger .internal-page h2 {
  font-size: 1.1818181818181819em;
  line-height: 1.18;
}
.internal-page h3 {
  font-size: 1em;
}
body.bigger .internal-page h3 {
  font-size: 1.0909090909090908em;
}
.internal-page h4 {
  font-size: 0.9444444444444444em;
}
body.big .internal-page h4 {
  font-size: 1em;
}
body.bigger .internal-page h4 {
  font-size: 1em;
}
.internal-page ol.breadcrumb {
  margin-left: 0px;
}
.internal-page ol.breadcrumb li {
  margin-top: 0px;
  margin-bottom: 0px;
}
.internal-page ol.breadcrumb li:last-child {
  color: #b75117;
}
.internal-page .pdflist ul li {
  padding: 8px 0px 6px 35px;
}
ul.tabs--primary.nav.nav-tabs {
  margin-bottom: 0px;
}
ul.tabs--primary.nav.nav-tabs li {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: inherit;
}
ul.tabs--primary.nav.nav-tabs li:before {
  display: none;
}
.plan-section {
  display: block;
  clear: both;
  margin-bottom: 20px;
}
.plan-section h4 {
  margin-top: 0;
}
.plan-section p {
  margin-bottom: 0;
}
.plan-section p a {
  display: inline-block;
  padding: 1rem 3rem;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  transition: all 0.2s ease-in 0s;
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
  border-radius: 20px;
}
.plan-section p a:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
  text-decoration: none;
}
.notice-section h2 {
  font-size: 24px;
  margin-top: 0;
}
.notice-section p {
  margin-bottom: 0;
}
.table caption {
  text-align: center;
}
.cOuter .field--name-body img {
  margin-top: 1rem;
}
.title-text {
  z-index: 1;
}
.title-text h1 {
  color: #fff;
  margin-top: 0;
}
.title-text h3 {
  color: #fff;
  margin-top: 0;
}
.clear {
  clear: both;
}
.cOuter p {
  margin-bottom: 0;
}
.cOuter p a {
  margin-top: 15px;
}
.messages__wrapper p {
  margin-top: 0;
  margin-bottom: 0;
}
.enroll-btn {
  padding: 9px 12px;
}
.side-section .nav > li > a {
  color: #000;
  line-height: 1.4;
  padding-top: 10px;
  padding-bottom: 10px;
}
.side-section .nav > li.active > a,
.side-section .nav > li > a:hover {
  color: #ec6c00;
}
.borderbox {
  border: 1px solid #66cbea;
  background: #e4f9fc;
  -webkit-border-radius: 5px;
  padding: 18px 18px !important;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.borderbox input {
  width: calc(100% - 138px);
  padding: 5px 0px;
  display: inline-block;
  padding: 5px 5px;
}
.contact-section h3 {
  margin-top: 0;
}
.contact-section a {
  color: #000;
}
.contact-section img {
  margin-bottom: 10px;
}
.progress-tracker--center .progress-step:before {
  display: none;
}
.logo img {
  width: 100%;
}
.search-form .input-group-btn .btn.icon-only {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 9px 12px 13px;
  border-left: none;
  height: 34px;
}
#modalagencts .modal-header {
  position: absolute;
  right: 0;
  z-index: 100;
  top: 0;
}
#modalagencts .modal-header {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
}
#modalagencts .modal-body {
  padding: 1.5em;
}
#modalagencts .modal-body h3 {
  margin-top: 0;
}
#modalagencts .modal-body #block-callcenteragents ul {
  list-style: none;
}
#modalagencts .modal-body #block-callcenteragents ul li {
  position: relative;
  padding: 0px 0px 0px 23px;
  margin-bottom: 10px;
}
#modalagencts .modal-body #block-callcenteragents ul li:before {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #000;
  left: 0;
  top: 8px;
  content: "";
  border-radius: 50%;
}
#modalagencts .modal-body #block-callcenteragents ul li ul {
  margin-top: 15px;
}
.rating-section {
  background: rgb(125, 185, 232);
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(125, 185, 232, 1) 0%,
    rgba(3, 163, 223, 1) 0%,
    rgba(31, 129, 174, 1) 100%
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(125, 185, 232, 1) 0%,
    rgba(3, 163, 223, 1) 0%,
    rgba(31, 129, 174, 1) 100%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(125, 185, 232, 1) 0%,
    rgba(3, 163, 223, 1) 0%,
    rgba(31, 129, 174, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7db9e8', endColorstr='#1f81ae',GradientType=1 );
  color: #fff;
}
.rating-section ul li {
  display: inline-block;
  vertical-align: middle;
}
.rating-section h2 {
  margin-top: 0;
}
.slide__bg {
  background-color: #f3f3f3;
  /*padding: 100px 0px;
    */
  height: 183px;
  position: relative;
  display: inline-block;
  width: 100%;
}
/*home page*/
.home-section h1 {
  margin-top: 0;
  border-bottom: none;
  font-size: 1.5em;
}
menu,
ol,
ul {
  padding: 0 0 0 30px;
}
ul {
  margin-bottom: 10px;
}
.leftarrowheading {
  text-shadow: 0px 0.2px 0.2px #ccc;
  background: url(../images/bullet-orange-arrow.gif) no-repeat top left;
  padding-left: 25px;
  padding-top: 3px;
}
ul.pdflist li,
li.pdflist,
.pdficon {
  padding: 0 0 5px 25px;
  background-image: url(../images/icon-pdf.png);
  background-repeat: no-repeat;
  background-position: 0 0.1em;
  list-style: none;
}
.pdficon {
  display: inline-block;
}
ul.pdflist li:before,
li.pdflist:before {
  display: none;
}
.internal-page .content ul.pdflist {
  padding: 0 0 0 30px;
}
td.cell-style-override-3 {
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 5px;
}
.home-section .field-collection-container.clearfix {
  margin-top: 30px;
}

/*---internal pages---*/
table {
  background-color: transparent;
}
table tbody tr td {
  padding: 10px;
}
table tbody tr:nth-child(even) {
  background: #f3f3f3;
}
.rightalign {
  text-align: right;
}
table.t1 {
  margin: 1em auto;
  border-collapse: collapse;
}
table thead,
table th {
  color: #333;
  font-size: 15px;
  line-height: 18px;
  border-bottom: 1px solid #cccccc;
  text-transform: uppercase;
  font-family: "Avenir LT W01 85 Heavy";
}
.t1 tr {
  border-right: 1px solid #95b3d7;
  border-left: 1px solid #95b3d7;
}
table tbody {
  font-family: "Avenir LT W01 45 Book";
  font-size: 0.8333333333333334em;
  line-height: 18px;
}
.t1 tbody tr {
  border-bottom: 1px solid #95b3d7;
}
.t1 tbody tr:nth-child(odd) {
  background: #dbe5f0;
}
table th {
  padding: 8px;
}
.t1 th,
.t1 td {
  padding: 4px 8px;
  text-align: left;
}
.t1 thead th {
  background: #4f81bd;
  text-align: left;
  color: #fff;
  border-right: 1px solid #4f81bd;
  border-top: 1px solid #4f81bd;
}
.t1 tbody th,
.t1 tbody td {
  border: none;
  border-right: 1px solid #95b3d7;
}
.t1 tfoot th {
  background: #4f81bd;
  border-top: 1px solid #95b3d7;
  text-align: left;
  font-weight: normal;
  font-size: 60%;
  color: #fff;
}
.invisible {
  display: none;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}
.zeromargintop {
  margin-top: 0;
}
h2.bordertop,
h3.bordertop,
h4.bordertop {
  border-top: 1px solid #32a4da;
  padding-top: 10px;
}
.btn.btn-default,
.form-actions .form-submit {
  background: #ff8716;
  background: -moz-linear-gradient(top, #ff8716 0%, #e13d00 100%);
  background: -webkit-linear-gradient(top, #ff8716 0%, #e13d00 100%);
  background: linear-gradient(to bottom, #ff8716 0%, #e13d00 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8716', endColorstr='#e13d00',GradientType=0 );
  padding: 9px 14px;
  color: #fff;
  font-size: 15px;
  line-height: 15px;
  border: 1px solid #ff8716;
  font-weight: normal;
  text-shadow: none;
  outline: none;
  border-radius: 5px;
  margin-right: 5px;
}
ul ul {
  list-style-type: circle;
}
a.agentbutton {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -webkit-box-shadow: #6e7849 0 0 3px;
  background-color: #527808;
  background-image: -moz-linear-gradient(180deg, #669900, #527808);
  background-image: -ms-linear-gradient(180deg, #669900, #527808);
  background-image: -o-linear-gradient(180deg, #669900, #527808);
  background-image: -webkit-linear-gradient(180deg, #669900, #527808);
  background-image: linear-gradient(180deg, #669900, #527808);
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
  font-size: 15px;
  margin: 0px 0 0 -100px;
  padding: 7px 15px;
  text-decoration: none;
  float: left;
}
a.agentbutton:hover {
  background-color: #71a607;
  background-image: -moz-linear-gradient(180deg, #5b8703, #71a607);
  background-image: -ms-linear-gradient(180deg, #5b8703, #71a607);
  background-image: -o-linear-gradient(180deg, #5b8703, #71a607);
  background-image: -webkit-linear-gradient(180deg, #5b8703, #71a607);
  background-image: linear-gradient(180deg, #5b8703, #71a607);
  text-decoration: underline;
}
#docspage ul.pdflist {
  text-indent: 0;
  padding: 0;
  margin-left: 0;
}
#docspage ul.nobullet {
  list-style-type: none;
  padding: 0 0 0 5px;
}
/* #docspage ul.pdflist li {
  background-image: url(/sites/default/files/PDF_21.png);
  margin-bottom: 0;
} */
#docspage ul.pdflist li ul li {
  margin-bottom: 0px;
  padding: 0px 0px 0px 30px;
}
#docspage ul.pdflist li ul {
  margin-bottom: 0;
}
/*form-area*/
/*Change payment option form*/
.page-header {
  padding-bottom: 0px;
  margin: 40px 0 20px;
  border-bottom: 1px solid transparent;
  font-size: 1.2em;
}
.webform-progress-tracker .progress-step .progress-marker {
  display: none;
}
.webform-submission-form #edit-actions button:nth-child(2) {
  margin-left: 1em;
}
.progress-step:not(:last-child):after {
  display: none;
}
.progress-step {
  flex: inherit;
}
.progress-tracker--center .progress-step:last-child {
  flex-grow: inherit;
}
.webform-progress-tracker.progress-tracker {
  background-color: #007fad;
}
.webform-progress-tracker .progress-step .progress-text {
  color: #fff;
  padding: 5px 18px;
}
.progress-tracker--center .progress-step.is-active {
  background-color: #c25700;
  color: #fff;
}
.webform-progress-tracker .progress-step.is-active .progress-text {
  color: #fff;
}
.webform-progress-tracker .progress-step.is-complete .progress-text {
  color: #fff;
}
.webform-submission-form .form-item.panel-default > .panel-heading {
  border-bottom: 1px double #3b64b8;
  background-color: transparent;
  font-weight: bold;
  padding: 9px 0px;
}
#edit-please-provide-your-medicare-insurance-information
  > .panel-body
  .panel-heading {
  border-bottom: 1px solid #e5e5e5;
}
.webform-submission-form .form-item.panel-default {
  border: none;
  box-shadow: none;
  margin-bottom: 0;
}
.webform-submission-form
  .form-item.panel-default
  > .panel-heading
  .panel-title {
  font-size: 20px;
}
.panel-body {
  padding: 15px 0 0;
}
.fieldset-wrapper .field-prefix .input-group-addon {
  word-break: break-word;
  white-space: normal;
  text-align: left;
  line-height: 1.5;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border-top: 0;
  border-bottom: 0;
}
.input-group-addon {
  font-size: 18px;
}
/*toggle up down arrow*/
#edit-step-2 .form-item.panel-default .panel-heading a.collapse-link {
  background: url(../images/arrow-up.png) no-repeat left center;
  padding-left: 20px;
}
#edit-step-2
  .form-item.panel-default
  .panel-heading
  a.collapse-link.cus-tag-link {
  background: url(../images/arrow-down.png) no-repeat left center;
}
.webform-submission-form #edit-accttype {
  margin-bottom: 10px;
}
.webform-submission-form
  #edit-accttype
  .form-item.js-form-item.form-type-radio {
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 15px;
}
/*date-picker-design*/
/*----- calender icon---- */

.input-group-addon {
  font-size: 18px;
}
.date_wrap_div {
  position: relative;
}
.input-group-addon {
  padding: 0px;
  background-color: transparent;
  border: none;
}
button.ui-datepicker-trigger {
  background-color: transparent;
  border: none;
  margin: 0 0 0 0px;
  padding: 3px 0px 3px 8px;
  position: absolute;
  top: 1px;
  left: 144px;
  margin-top: 4px;
  z-index: 3;
}
img.ui-datepicker-trigger {
  background-color: transparent;
  border: none;
  margin: 0 0 0 0px;
  padding: 3px 0px 3px 8px;
  position: absolute;
  top: 3px;
  left: 144px;
  margin-top: 4px;
  z-index: 3;
}
button.ui-datepicker-trigger:focus,
img.ui-datepicker-trigger:focus {
  box-shadow: none;
  outline: none;
}
/* #edit-step-2 img.ui-datepicker-trigger{
     left: 169px;
}
 */
/*#edit-row-2 button.ui-datepicker-trigger{
     left: 186px;
}
 */
button.ui-datepicker-trigger img {
  position: relative;
  top: -2px;
}
.webform-submission-form .form-item {
  position: relative;
}
.js-webform-novalidate.button {
  position: absolute;
  right: 15px;
  top: 9px;
}
.js-webform-novalidate.button .icon.glyphicon {
  display: none;
}
/*calender-section*/
.ui-widget.ui-widget-content {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
  padding: 0px;
}

.ui-widget-content a {
  color: #337ab7;
  text-decoration: underline;
}

.ui-widget.ui-widget-content table.ui-datepicker-calendar thead {
  background-color: #e7e9eb;
}
.ui-datepicker .ui-datepicker-header {
  background-color: transparent;
  padding: 10px 5px;
}
.ui-datepicker td {
  padding: 5px 5px;
}
.ui-datepicker table th {
  font-size: 13px;
  font-family: "Avenir LT W01 55 Roman";
}
.ui-widget-content .ui-state-default {
  border-radius: 3px;
  text-align: center;
  border: none;
  background-color: transparent;
}
.ui-widget-content .ui-state-active,
.ui-widget-content .ui-state-default.ui-state-highlight {
  border: 1px solid #3b64b8;
  background: #3b64b8;
  color: #fff;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  border: 1px solid #055a8d;
  background: #055a8d;
}
.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 5px 3px;
  height: 30px;
  font-family: "Avenir LT W01 55 Roman";
}
.ui-datepicker .ui-datepicker-prev span {
  background: url(../images/previous-arrow.png) no-repeat;
}
.ui-datepicker .ui-datepicker-next span {
  background: url(../images/next-arrow.png) no-repeat;
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  width: 100%;
  height: 100%;
  left: 0;
  margin-left: 0;
  top: 5px;
  margin-top: 0;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  top: 14px;
}
.ui-widget-header .ui-state-hover {
  border: none;
  background-color: transparent;
}
/*------*/
.webform-submission-form .form-item input.error,
.webform-submission-form .form-item textarea.error,
.webform-submission-form .form-item select.error {
  border: 1px solid #c00;
  background-color: #fef5f1;
  color: #ff0000;
  margin-right: 15px;
}
.webform-submission-form .form-item h1 {
  margin-top: 0;
}
.webform-submission-form .form-radio.error + label.error,
.webform-submission-form .form-item input[type="checkbox"].error + label.error {
  float: right;
  padding-left: 10px;
}
.webform-progress-tracker.progress-tracker {
  margin-bottom: 0px;
}
.webform-submission-form label.error,
.webform-submission-form .messages--error {
  color: #ff0000;
  font-weight: normal;
}
/*------az-website------*/
div.hpleft {
  border-right: 1px solid #bbd3de;
  padding-right: 25px;
}
p.bluebox {
  background: #dbebf9;
  border-radius: 5px;
  padding: 8px;
}
div.hpleft p,
div.hpright p {
  margin-left: 0;
}
div.hpleft a.btn {
  float: right;
  position: relative;
  bottom: 6px;
  padding: 6px;
}
span.footnote {
  float: left;
  display: block;
}
div.hpleft h2,
div.hpright h2 {
  font-size: 1.2em;
  margin-bottom: 23px;
}
div.hpright {
  padding-left: 20px;
}
div.health h2 {
  margin-bottom: 0;
}
div.health img {
  float: left;
  padding-right: 10px;
}
div.hpright p img {
  float: left;
  padding-right: 10px;
}

div.hpright hr {
  border-top: 1px solid #bbd3de;
}
/*div.hpleft h2, div.hpright h2 {
     margin-bottom: 23px;
}
 */
div.hpleft h2,
div.hpright h2 {
  font-size: 1.2em;
}
.center {
  text-align: center;
}
#block-az-front-block {
  background-color: #fff;
  border: 1px solid #bbd7d3;
  padding: 15px 15px;
  margin-top: 15px;
  display: block;
  clear: both;
}
.npa-gradient p {
  color: #333;
  font-size: 0.9444444444444444em;
  margin-bottom: 20px;
}
table.plantable {
  border: 3px solid #fff;
  border-collapse: separate;
  border-spacing: 2px;
  line-height: 180%;
  margin: 0 auto;
  text-align: center;
  width: 70%;
}
table.plantable th {
  background-color: #00a3e4;
  color: #fff;
  padding: 1em;
  font-size: 12px;
  text-transform: none;
  text-align: center;
  font-family: Arial, tahoma, sans-serif;
}
table.plantable a:link,
table.plantable a:visited,
table.plantable a:active {
  color: #fff;
  display: block;
}
table.plantable tr.premium {
  background: #c4d7e5;
  font-weight: bold;
}
tr.premium td {
  padding: 10px;
  font-size: 14px;
}
table.plantable a:link,
table.plantable a:visited,
table.plantable a:active {
  color: #fff;
  display: block;
}
table.plantable tr.premium a:link,
table.plantable tr.premium a:visited,
table.plantable tr.premium a:active {
  color: #000;
  display: block;
}
span.biggertxt {
  font-size: 16px;
}
/*-----*/
/*----breadcrumb---*/
.breadcrumb {
  padding: 8px 0px 0;
  margin-bottom: 0px;
  list-style: none;
  background-color: transparent;
}
.breadcrumb > li {
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
}
.breadcrumb > li a {
  color: #000;
  text-transform: uppercase;
}
.breadcrumb > .active {
  color: #f17814;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #b0114a;
  text-decoration: none;
  background-color: #fff;
  outline: 0;
}
/*----yourazmedicaresolutions----*/
p.blueboxpdp {
  background: #dbebf9;
  border-radius: 5px;
  padding: 15px;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  width: 100%;
}
.page-node-72 .row.cus-table-data .col-md-12.full-width {
  padding: 0;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
div.bluebox {
  background: #dbebf9;
  border-radius: 5px;
  padding: 0px 10px 15px;
}
.headingback {
  border-bottom: 1px solid #b8dada;
  padding: 0 0 3px 0;
  background-image: -webkit-linear-gradient(#ffffff, #aaddaa);
  background-image: -moz-linear-gradient(#aaddaa, #ffffff);
  background-image: -o-linear-gradient(#aaddaa, #ffffff);
  background-image: linear-gradient(#ffffff, #e3f0f0);
}
h2 > a.jumpdown {
  font-size: 80%;
}
a.jumpdown {
  font-size: 100%;
  margin-left: 2.5em;
  font-weight: normal;
  vertical-align: baseline;
  padding: 0 20px 5px 0px;
  background-image: url(../images/downarrow.png);
  background-repeat: no-repeat;
  background-position: right center;
}
a.totop,
a.goback,
a.gobackregular {
  margin-left: 1.5em;
  font-size: 80%;
  font-weight: normal;
  vertical-align: middle;
  padding: 0 0 5px 20px;
  background-image: url(../images/pagetop.png);
  background-repeat: no-repeat;
  background-position: 0 0.1em;
}
/*stick navigation*/
.search-block-form .form-group {
  margin-bottom: 0;
}
.navbar-toggle {
  margin-top: 0px;
  margin-right: 0;
}
.header_wrapper_section {
  background: rgb(213, 228, 232);
  background: -moz-linear-gradient(
    left,
    rgba(213, 228, 232, 1) 0%,
    rgba(241, 246, 250, 1) 20%,
    rgba(241, 246, 250, 1) 48%,
    rgba(213, 228, 232, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(213, 228, 232, 1) 0%,
    rgba(241, 246, 250, 1) 20%,
    rgba(241, 246, 250, 1) 48%,
    rgba(213, 228, 232, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(213, 228, 232, 1) 0%,
    rgba(241, 246, 250, 1) 20%,
    rgba(241, 246, 250, 1) 48%,
    rgba(213, 228, 232, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d5e4e8', endColorstr='#d5e4e8',GradientType=1 );
  width: 100%;
  display: block;
}
img {
  max-width: 100%;
  height: auto;
}
.t1 th.smaller {
  font-size: 12px;
  vertical-align: middle;
  text-align: center;
}
/* .borderboxplus {
     border: 4px double #55AF55;
     padding: 8px;
     background: #F8FEF0;
     -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
     border-radius: 5px;
}
*/
h2.large {
  border-top: 1px solid #32a4da;
  font-size: 120%;
  padding-top: 10px;
}
/*-----------------BTN-ENROLL------------*/
.btn-enroll {
  background: #f17813;
  color: #fff;
  border: 2px solid #f17813;
}
.btn-enroll:hover {
  background: transparent;
}
.btn-default.btn-enroll:hover {
  border: 2px solid #0070c0;
  color: #0070c0;
}
.btn-warning.btn-enroll:hover {
  border: 2px solid #f17813;
  color: #f17813;
}
.form-required:after {
  content: "*";
  color: red;
}
.modal-body .input-group {
  width: 100%;
  display: block;
}
.modal-body .input-group-addon {
  display: none;
}
.m-icon {
  float: right;
  margin: 0px 36px 0px 0px;
}
.m-icon .field p {
  margin-bottom: 0;
}
.m-search.btn.icon-only {
  display: none;
}
b,
strong {
  font-family: "Avenir LT W01 65 Medium";
}
table.t1 caption {
  font-weight: bold;
  background: #cdcdcd;
  display: table-caption;
  text-align: center;
  padding: 0px;
  color: #333;
}
table tbody tr:nth-child(even) {
  background: #f3f3f3;
}

#accordion-74 > h3,
#accordion-30 > h3,
#accordion-82 > h3 {
  margin-top: 15px !important;
}
.ui-accordion .ui-accordion-content {
  padding: 1em 1em;
}
table {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.t2 tr {
  border-right: 1px solid #878787;
  border-left: 1px solid #878787;
}
.t2 tbody tr {
  border-bottom: 1px solid #878787;
}
.t2 tbody tr:nth-child(odd) {
  background: #e4e4e4;
}
table tbody tr:nth-child(even) {
  background: #f3f3f3;
}
.t2 tbody th,
.t2 tbody td {
  border-right: 1px solid #878787;
}
.pageBody a,
.copyrightContainer a {
  color: #0000ee;
}
/*npa slider*/
.slider-area .slider-image + .title-text,
.slide__bg .internal-banner-section {
  width: 75%;
  background: -moz-linear-gradient(
    left,
    rgba(31, 129, 174, 1) 0%,
    rgba(31, 129, 174, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(31, 129, 174, 1) 0%,
    rgba(31, 129, 174, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(31, 129, 174, 1) 0%,
    rgba(31, 129, 174, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f81ae', endColorstr='#001f81ae',GradientType=1 );
  padding: 20px 20px 0px 20px;
  margin-bottom: 0;
}
.slider-image .field.field--name-body .slide-pic + h1,
.slider-image .field.field--name-body .slide-pic + h3 {
  display: none;
}
/*toggle up down arrow*/
#edit-please-provide-your-medicare-insurance-information
  > .panel-body
  .panel-heading {
  border-bottom: 1px solid #e5e5e5;
}
#edit-step-2--2 .form-item.panel-default > .panel-heading {
  border-bottom: 1px solid #e5e5e5;
  background-color: transparent;
  font-weight: bold;
  padding: 9px 0px;
}
#edit-step-2--2 .form-item.panel-default .panel-heading a.collapse-link {
  background: url(../images/arrow-up.png) no-repeat left center;
  padding-left: 20px;
}
#edit-step-2--2
  .form-item.panel-default
  .panel-heading
  a.collapse-link.cus-tag-link {
  background: url(../images/arrow-down.png) no-repeat left center;
}
.send-confirmation {
  display: inline-block;
  vertical-align: middle;
}
/*side bar box*/
.region.region-sidebar-first nav + .block-block-content {
  background-color: #fff;
  border: 1px solid #bbd7d3;
  padding: 15px 15px;
  margin-top: 20px;
  display: inline-block;
  width: 100%;
}
/*contact us page*/
.slide__bg .internal-banner-section h1,
.slide__bg .internal-banner-section p {
  margin-top: 0;
  color: #fff;
}
.slide__bg .internal-banner-section p {
  font-size: 1.176em;
  font-family: "Avenir LT W01 85 Heavy";
  line-height: 1.4;
  font-weight: 500;
}
.slide__bg .internal-banner-section {
  display: inline-block;
}
.slide__bg .banner-content {
  position: absolute;
  left: 0px;
  bottom: 0px;
}
/*from progress step completed text*/
.progress-step.is-complete .progress-text .progress-title {
  color: #fff;
}
table.ui-datepicker-calendar tbody tr:nth-child(even) {
  background-color: transparent;
}
#edit-dob-error.error {
  width: 100%;
  float: left;
  text-align: left;
  padding-top: 5px;
}
.w3-btn,
.w3-button {
  border: none;
  display: inline-block;
  outline: 0;
  padding: 3px 0px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: #0066ff;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  overflow-wrap: break-word;
}
.w3-block {
  display: block;
  width: 100%;
}
.w3-left-align {
  text-align: left !important;
}
.w3-white,
.w3-hover-white:hover {
  color: #0066ff !important;
  background-color: #f3f3f3 !important;
}
.w3-hide {
  display: none !important;
}
.w3-animate-zoom {
  animation: animatezoom 0.6s;
}
@keyframes animatezoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.w3-show-block,
.w3-show {
  display: block !important;
}
.side-section #block-az-front-block-2 {
  background-color: #fff;
  border: 1px solid #bbd7d3;
  padding: 15px 15px;
  display: block;
  clear: both;
}
#block-rightsnoticeazbluepreenroll {
  background-color: #fff;
  width: 100%;
  border: 1px solid #bbd7d3;
  padding: 15px 15px;
  display: block;
  clear: both;
  background-color: #fff;
  margin-top: 30px;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(238, 244, 246, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(238, 244, 246, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(238, 244, 246, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eef4f6',GradientType=0 );
}

.contact-gradient {
  background-color: #fff;
  margin-top: 30px;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(238, 244, 246, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(238, 244, 246, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(238, 244, 246, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eef4f6',GradientType=0 );
  border: 1px solid #bbd7d3;
}

.contact-gradient h2 {
  margin-top: 0;
}

.contact-youraz,
.notice-rights {
  float: left;
  clear: both;
  border: 1px solid #d7e8f7;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(238, 244, 246, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(238, 244, 246, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(238, 244, 246, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eef4f6',GradientType=0 );
}
.notice-rights h2,
.contact-youraz h2 {
  margin-top: 0;
}
.notice-rights .field--type-text-with-summary p a {
  color: #ec6c00;
  padding-right: 10px;
  background: url(../images/arrow_rt.png) right center no-repeat;
}
table.plantable2 {
  border: 3px solid #fff;
  border-collapse: separate;
  border-spacing: 2px;
  line-height: 180%;
  margin: 0 auto;
  text-align: center;
  width: 60%;
}
table.plantable2 th {
  background-color: #00a3e4;
  color: #fff;
  padding: 1em;
  font-size: 20px;
  text-transform: none;
  text-align: center;
  font-family: Arial, tahoma, sans-serif;
  line-height: 160%;
}
table.plantable2 span.biggertxt {
  font-size: 28px;
}
table.plantable2 tr.premium {
  background: #c4d7e5;
  font-weight: bold;
  line-height: 200%;
}
table.plantable2 tr.premium td {
  padding: 10px;
  font-size: 18px;
}
table.plantable2 span.biggertxt {
  font-size: 28px;
}
h2 + p,
h3 + p,
h4 + p {
  margin-top: -5px;
}
#block-enrollrating-4 {
  margin-top: 0;
}
#block-homecontact-2 {
  text-align: left;
}
/*--here providers page code 03-06-2019--*/
.page-node-103 article.standard .field .col-sm-6 > .field__item,
.page-node-81 article.standard .field .col-sm-6 > .field__item,
.page-node-81 article.standard .field .field__item.full-width,
.page-node-105 article.standard .field .col-sm-6 > .field__item {
  background: #f3f3f3;
  padding: 20px 20px 20px 20px;
  margin-bottom: 10px;
  border-radius: 4px;
}
.page-node-81
  article.standard
  .field
  .field__item.full-width
  .col-sm-6
  > .field__item {
  padding: 0px;
  border-radius: 0px;
}
.page-node-103
  article.standard
  .field
  .col-sm-6
  > .field__item
  .field-collection-container
  > .field__item.odd,
.page-node-81
  article.standard
  .field
  .col-sm-6
  > .field__item
  .field-collection-container
  > .field__item.odd,
.page-node-81
  article.standard
  .field
  .field__item.full-width
  .field-collection-container
  > .field__item.odd,
.page-node-105
  article.standard
  .field
  .col-sm-6
  > .field__item
  .field-collection-container
  > .field__item.odd {
  font-size: 1.05556em;
  line-height: 24px;
  font-family: "Avenir LT W01 85 Heavy";
  border-bottom: 1px solid #dadada;
  padding-bottom: 10px;
  margin-bottom: 15px;
  color: #333333;
}
.page-node-72 .field__item.full-width,
.page-node-22 .field__item.full-width {
  background: #f3f3f3;
  padding: 20px 20px;
  margin-bottom: 20px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 20px;
  border: 1px solid #bbd7d3;
}
.page-node-72 .field__item.full-width .field__item.odd,
.page-node-22 .field__item.full-width .field__item.odd {
  border-bottom: 1px solid #dadada;
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-size: 1.0555555555555556em;
  font-family: "Avenir LT W01 85 Heavy";
}
.page-node-72 .field__item.full-width .col-sm-6 .field__item.odd,
.page-node-22 .field__item.full-width .col-sm-6 .field__item.odd {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-family: "Avenir LT W01 55 Roman";
  font-size: 1em;
}
.page-node-81 .field.field--name-field-table-menu-content .field__item,
.page-node-103 .field.field--name-field-table-menu-content .field__item {
  margin-bottom: 20px;
}
.page-node-81
  .field.field--name-field-table-menu-content
  .field__item
  .field--name-field-link-section-url
  .field__item,
.page-node-103
  .field.field--name-field-table-menu-content
  .field__item
  .field--name-field-link-section-url
  .field__item {
  margin-bottom: 0;
}
.page-node-81
  .field.field--name-field-table-menu-content
  .field__item:last-child,
.page-node-103
  .field.field--name-field-table-menu-content
  .field__item:last-child {
  margin-bottom: 0px;
}
.page-node-81
  .field__item
  .field-collection-container
  .field__item
  .field__item
  a {
  color: #23527c;
  text-decoration: underline;
}

.progress-tracker--center .progress-step.is-complete {
  cursor: pointer;
}

#block-three-star-bcbsaz-2 h2 {
  margin-top: 0;
}

.table-responsive {
  overflow-x: inherit;
}

.help-block {
  color: #000;
}

.para-left {
  float: left;

  position: relative;
  margin-bottom: 20px;
}

.para-right {
  float: right;

  position: relative;
  border-radius: 5px;
  padding: 0 12px 12px;
  border: 1px solid #d3d3d3;
  background: #fafafa;
  margin-bottom: 25px;
  margin-top: -45px;
}

/*close*/
/*media start here*/
@media only screen and (min-width: 1024px) {
  .para-left {
    width: 54%;
  }

  .para-right {
    width: 45%;
  }

  span.cat {
    position: absolute;
    top: 25px;
    right: 30px;
  }

  .home-section span.cat {
    top: 11px;
  }
  .side-section .navbar-nav li:hover ul {
    display: block !important;
  }

  .slide__bg .banner-content {
    left: 50%;
    transform: translateX(-50%);
  }
  .caret {
    border-top: 4px dashed;
    display: inline-block;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  .npa.navbar-default ul li ul li a span.caret {
    position: absolute;
    right: 15px;
    top: 18px;
    transform: rotate(270deg);
  }
  .slider-area .title-text h1,
  .slide__bg .internal-banner-section h1 {
    line-height: 1.2;
  }
  .cost-section {
    height: 100%;
  }
  .contact-field.col-md-4 {
    float: none;
    display: flex;
  }
  .c-area {
    display: flex;
  }
  .page-node-72 .field--name-field-table-menu > .row .col-sm-6 {
    padding: 0;
  }
}
@media (min-width: 980px) and (max-width: 1180px) {
  .container {
    width: 100%;
  }
  .navbar-nav > li > a {
    padding-left: 25px;
    padding-right: 25px;
  }
  body.big .navbar-nav > li > a {
    padding-left: 15px;
    padding-right: 15px;
  }
  body.bigger .navbar-nav > li > a {
    padding-left: 10px;
    padding-right: 10px;
  }

  .navbar-default .navbar-nav > li > span.cus-documents {
    padding-left: 25px;
    padding-right: 25px;
  }

  body.azbluemedicare_connectrouting_com .navbar-nav > li > a {
    padding-left: 22px;
    padding-right: 22px;
  }
}
@media (min-width: 980px) {
  .pt-md-5 {
    padding-top: 3rem;
  }
  /*common-classes*/

  .pr-md-0 {
    padding-right: 0;
  }
  .pl-md-0 {
    padding-left: 0;
  }
  #navbar {
    z-index: 1;
    position: relative;
  }
  .cSection {
    display: flex;
  }
  .cOuter {
    min-height: 250px;
  }
  .npa.navbar-default ul {
    min-height: 40px;
    padding-left: 0;
    transition: transform 0.35s ease-out;
    width: 100%;
    margin-top: 10px;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
  }
  .npa.navbar-default ul li ul {
    border-radius: 0px;
    margin-top: 0px;
  }
  .npa.navbar-default ul li {
    display: inline-block;
    vertical-align: top;
    margin: 0 -2px;
    float: none;
    /* background-color: #F0F5F7;
        */
    border-left: 2px solid #d8d8d8;
    border-top: 2px solid #d8d8d8;
    border-bottom: 2px solid #2d74b5;
    background: #ffffff;
    background: -moz-linear-gradient(top, #ffffff 0%, #eef4f6 100%);
    background: -webkit-linear-gradient(top, #ffffff 0%, #eef4f6 100%);
    background: linear-gradient(to bottom, #ffffff 0%, #eef4f6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eef4f6',GradientType=0 );
  }

  .npa.navbar-default ul li:last-child {
    border-right: 2px solid #d8d8d8;
  }
  .npa.navbar-default .navbar-nav > .active {
    border-left: 2px solid #2d74b5;
    border-top: 2px solid #2d74b5;
  }
  .npa.navbar-default ul li ul li {
    width: 100%;
    text-align: left;
    border: none;
    border-bottom: 1px solid #dadada;
  }
  .npa.navbar-default ul li ul {
    width: 250px;
  }

  .npa.navbar-default ul li.cus-members ul {
    /*height: 400px; overflow-x: auto;*/
  }

  .npa.navbar-default ul li ul li a {
    display: block;
    padding: 9px 20px;
    background-color: #fff;
    font-size: 18px;
  }
  .npa.navbar-default ul li ul li:last-child {
    border-right: none;
    border-bottom: none;
  }
  .npa.navbar-default ul li ul li ul {
    left: 247px;
    top: 0;
  }
  /*sticky-navigation*/
  .header_wrapper_section {
    background-color: #fff;
  }
  #stick-bar .container {
    position: relative;
    z-index: 100;
  }
  a.logo.navbar-btn {
    width: auto;
    transition: all 0.5s ease-in 0s;
    margin-bottom: 0;
  }
  .fixed-top a.logo.navbar-btn {
    width: 300px;
    top: 0px;
    transition: all 0.5s ease-in 0s;
    margin: 0;
    margin-top: 5px;
  }
  .fixed-top a.logo.az-logo.navbar-btn {
    width: 160px;
  }
  .fixed-top .right-section {
    padding-top: 9px;
  }
  .fixed-top .py-4.head-point {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .head-point .region-header-left .search-block-form {
    display: none;
  }
  .dropdown:hover > .dropdown-menu {
    display: block;
  }

  .navbar-default .navbar-nav > li > span.cus-documents {
    padding-top: 13px;
    padding-bottom: 12px;
  }
}
@media (min-width: 1280px) {
  body {
    font-size: 18px;
    line-height: 1.5;
  }

  .navbar-default .navbar-nav > li > span.cus-documents {
    padding-top: 13px;
    padding-bottom: 10px;
  }

  .container {
    width: 1200px;
    padding-right: 0;
    padding-left: 0;
  }
  .right-section {
    float: right;
    padding-top: 18px;
    transition: all 0.5s ease-in 0s;
  }
  .navbar-default .navbar-nav > li > a,
  .navbar-default .navbar-nav > li > span.cus-documents {
    position: relative;
    padding-left: 19px;
    padding-right: 19px;
  }

  body.big .nav > li > a,
  body.big .navbar-default .navbar-nav > li > span.cus-documents {
    padding-left: 27px;
    padding-right: 27px;
  }
  body.bigger .navbar-default .navbar-nav > li > a,
  body.bigger .navbar-default .navbar-nav > li > span.cus-documents {
    position: relative;
    padding-left: 22px;
    padding-right: 22px;
  }
  body.big .side-section .nav > li > a,
  body.bigger .side-section .nav > li > a {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  .cOuter .field--name-body {
    margin-bottom: 15px;
    min-height: 269px;
    padding: 1rem;
  }
  .slider-content {
    padding-bottom: 0;
  }
  .slider-area:before {
    width: 100%;
  }
  .navbar-default .navbar-nav > li > a span.caret {
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    line-height: 30px;
  }
  .caret {
    width: 40px;
    height: 40px;
  }
  .caret:before {
    content: "";
    border-top: 4px dashed;
    display: inline-block;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  .navbar-toggle {
    display: block;
    margin-right: 0;
  }
  #stick-bar .container {
    width: 100%;
    padding: 0;
  }
  .navbar-default .navbar-collapse {
    padding: 0;
  }
  .navbar-nav > li:hover {
    background: transparent;
  }
  .nav > li {
    display: block;
    width: 100%;
    clear: both;
  }

  .bottom-links ul li {
    width: auto;
  }
}
@media (min-width: 768px) {
  body {
    font-size: 18px;
  }
  .home-section.cus-ncqa {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-brand {
    padding-left: 0;
    padding-right: 0;
  }
  .cButton {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 0px 15px;
  }
  .dropdown-caret {
    position: absolute;
    top: 48%;
    right: 6px;
  }
  .d-inline-block {
    display: inline-block !important;
  }
  .form-inline .control-label {
    margin-bottom: 5px;
    width: 100%;
  }
  .form-inline .date_wrap_div input.form-control {
    width: 180px;
  }
  /*form-section*/
  #edit-med-id-row .col-md-6.form-item.js-form-item input {
    width: 180px;
  }

  .order-md-2 {
    order: 2;
  }
  .order-md-1 {
    order: 1;
  }

  .d-md-flex {
    display: flex;
  }
}
@media only screen and (min-width: 768px) and (max-width: 979px) {
  .area-search {
    box-shadow: none;
    width: 100%;
  }
  .navbar-default .navbar-nav .dropdown.open .dropdown-menu > li > a {
    color: #fff;
  }
  .right-section {
    float: right;
    padding-top: 21px;
    margin-right: 0px;
  }
  .logo {
    width: 200px;
  }
  .list-group,
  .side-section ul {
    margin-right: 0;
    margin-left: 0;
  }
  .internal-page h1 {
    font-size: 28px;
  }
  .internal-page h2 {
    font-size: 24px;
  }
  .m-icon {
    margin: 0px 40px 0px 0px;
  }

  .main-container.pt-md-5.container {
    padding-top: 25px;
  }
}
@media (max-width: 767px) {
  /*provider*/
  .page-node-103 article.standard .field .col-sm-6 > .field__item,
  .page-node-81 article.standard .field .col-sm-6 > .field__item,
  .page-node-81 article.standard .field .field__item.full-width {
    margin: 7.5px 0;
    display: inline-block;
    width: 100%;
  }
  .page-node-103 article.standard .field .col-sm-6 > .field__item:first-child,
  .page-node-81 article.standard .field .col-sm-6 > .field__item:first-child {
    margin-top: 0px;
  }
  .page-node-81
    article.standard
    .field
    .col-sm-6
    > .field__item.right:first-child {
    margin-top: 7.5px;
  }
  .page-node-103 aside.side-section,
  .page-node-81 aside.side-section {
    margin-top: 30px;
  }
  /*date error form*/
  .webform-submission-form
    .form-item
    span.input-group-addon
    .date_wrap_div
    input.error
    + label.error {
    float: left;
    margin-top: 6px;
  }
  .webform-submission-form
    .form-item
    span.input-group-addon
    .date_wrap_div
    input.error {
    margin: 0;
  }
  /*ui icon alignment*/
  img.ui-datepicker-trigger {
    right: 15px;
    left: auto;
  }
  /*send notification button*/
  .send-confirmation {
    margin-bottom: 20px;
  }
  .cOuter .field--name-body {
    margin-bottom: 15px;
    padding: 1.5rem;
  }
  .slider-content {
    padding-bottom: 0;
  }
  .slider-area:before {
    width: 100%;
  }
  .column-area.row {
    margin: 0;
  }
  .slide {
    position: relative;
    height: auto;
  }
  .carousel-inner.h-100 {
    height: auto;
  }
  .npa-slider .white-color {
    color: #000;
  }
  .slider-content {
    padding-top: 0px;
  }
  .area-search {
    box-shadow: none;
    width: 100%;
  }
  #edit-step-5-title .btn.btn-default.cboxElement {
    width: 100%;
    white-space: normal;
  }
  .navbar-nav .dropdown .dropdown-caret {
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;
  }
  .dropdown-menu > li > a {
    white-space: initial;
  }

  div.hpleft {
    border-right: none;
  }

  table.plantable {
    width: 100%;
  }
}
/*all-pages-responsive-layout*/
@media (max-width: 979px) {
  .slide__bg .banner-content {
    padding: 0px;
  }
  /*webform-submission-form date*/
  .webform-submission-form label#edit-dob-error.error {
    width: 100%;
    float: left;
    text-align: left;
    padding-top: 2px;
  }
  /*slider text*/
  .slider-area .slider-image + .title-text h1,
  .slide__bg .internal-banner-section h1 {
    font-size: 30px;
    margin-bottom: 0;
  }
  .slider-area .slider-image + .title-text h3,
  .slide__bg .internal-banner-section p {
    font-size: 20px;
  }
  /*close*/
  .container {
    width: 100%;
  }
  .slider-area .container {
    padding: 0px 0px;
  }
  .slider-area {
    padding: 0px 0;
  }
  .slider-image .field.field--name-body {
    width: 100%;
    height: 100%;
    display: block;
    padding: 102px 20px;
  }
  .slider-image .field.field--name-body .slide-pic {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  .slider-area .slider-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  .cost-section {
    margin-bottom: 15px;
  }
  .navbar-default .navbar-nav > li > a span.caret,
  .navbar-default .navbar-nav > li ul li > a span.caret {
    position: absolute;
    right: 0;
    top: 7px;
    text-align: center;
    line-height: 30px;
    border: none;
  }

  .npa.navbar-default ul li span.cus-documents span.caret {
    position: absolute;
    right: 0;
    top: 7px;
    text-align: center;
    line-height: 30px;
    width: 40px;
    height: 40px;
  }

  .caret {
    width: 40px;
    height: 40px;
  }
  .caret:before {
    content: "+";
    border-top: 0px dashed;
    display: inline-block;
    border-top: 0px solid \9;
    border-right: 0px solid transparent;
    border-left: 0px solid transparent;
    height: 100%;
    padding: 0px 0px 0px;
  }

  .side-section .navbar-nav li a span.caret:before {
    height: auto;
  }

  .npa.navbar-default ul li span.cus-documents span:before {
    height: auto;
    content: "+";
    border-top: 0px dashed;
    display: inline-block;
    border-top: 0px solid \9;
    border-right: 0px solid transparent;
    border-left: 0px solid transparent;
    padding: 0px 0px 0px;
    line-height: inherit;
    top: 0px;
  }

  .navbar-nav > li > .dropdown-menu {
    width: 100%;
    position: relative;
    border: none;
    box-shadow: none;
    border-radius: 0;
    background-color: #fff;
    padding: 0px 0px;
    margin: 12px 0px;
  }

  .navbar-nav > li ul.dropdown-menu li {
    position: relative;
  }

  .navbar-nav > li ul.dropdown-menu li ul.dropdown-menu {
    position: relative;
    border: none;
    box-shadow: none;
  }

  .navbar-nav > li ul.dropdown-menu li.active ul.dropdown-menu li a {
    color: #000;
  }

  .navbar-nav > li ul.dropdown-menu li ul.dropdown-menu {
    position: relative;
    border: none;
    box-shadow: none;
  }

  .navbar-nav > li ul.dropdown-menu li ul.dropdown-menu {
    position: relative;
    border: none;
    box-shadow: none;
  }

  .side-section .navbar-nav li:hover ul {
    display: block !important;
  }

  .navbar-nav > li {
    border-bottom: 1px solid #e9eae9;
    width: 100%;
    float: left;
    clear: both;
  }
  /*navigation-structure*/
  .container > .navbar-header,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0;
    padding-left: 0;
    border-color: transparent;
  }
  #stick-bar .container {
    width: 100%;
    padding: 0;
  }
  #stick-bar .search-block-form {
    display: block;
    display: block;
    margin-top: 25px;
  }
  .navbar-header {
    position: absolute;
    right: 11px;
    top: 21px;
  }
  .navbar-toggle .icon-bar {
    background-color: #000;
  }
  .npa.navbar-default {
    min-height: auto;
    position: fixed !important;
    top: 65px;
    width: 250px;
    height: calc(100% - 65px);
  }
  .npa.navbar-default {
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
    left: -270px;
    transition: all 0.2s ease-in 0s;
  }
  .npa.navbar-default.jr-open {
    left: 0;
    overflow-y: auto;
    padding-bottom: 50px;
  }
  .npa.navbar-default ul li a,
  .npa.navbar-default ul li span.cus-documents {
    padding-left: 15px;
    padding-right: 45px;
  }

  .npa.navbar-default ul li span.cus-documents {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-default .navbar-nav > li > a {
    color: #0070c0;
  }
  .npa.navbar-default ul li span.cus-documents {
    color: #0070c0;
  }
  .navbar-default .navbar-nav > li.active > a span.caret,
  .npa.navbar-default ul li.active span.cus-documents span.caret {
    color: #fff;
  }
  .navbar-default .navbar-nav > li.active > a span.caret,
  .npa.navbar-default ul li.active span.cus-documents span.caret {
    color: #fff;
  }
  .navbar-nav {
    display: block;
    clear: both;
    width: 100%;
    margin: 0;
  }
  .right-section {
    display: none;
  }
  .head-point {
    z-index: 10;
    width: 100%;
    border-bottom: 1px solid #fff;
    padding-bottom: 5px;
  }
  .contact-area .field--name-body p br {
    display: none;
  }
  .notice-section h2 {
    font-size: 18px;
  }
  .m-search.btn.icon-only {
    border-radius: 4px !important;
    border: none;
    padding-left: 12px;
    padding-right: 12px;
    float: right;
    margin-top: 10px;
    background-image: none;
    background-color: transparent;
    color: #3b64b8;
    display: block;
    box-shadow: none;
  }
  .btn.icon-only span.glyphicon-search {
    font-size: 0px;
  }
  .btn.icon-only span.glyphicon-search:before {
    font-size: 15px;
  }
  .search-block-form {
    width: 100%;
    position: absolute;
    left: 0;
    top: -200px;
    background-color: #ffff;
    z-index: 1;
    transition: all 0.2s ease-in 0s;
    padding: 24px 20px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease-in 0s;
    display: block;
  }
  .search-block-form.m-search-block-form {
    top: 0;
    transition: all 0.3s ease-in 0s;
  }
  li.submenu-open a .caret:before,
  .npa.navbar-default ul li.submenu-open span.cus-documents span:before {
    content: "-";
  }
}
@media (min-width: 568px) and (max-width: 979px) {
  /*slider text gredient*/
  .slider-area .slider-image + .title-text,
  .slide__bg .internal-banner-section {
    width: 100%;
    background: rgb(31, 129, 174);
    background: -moz-linear-gradient(
      left,
      rgba(31, 129, 174, 1) 0%,
      rgba(183, 209, 229, 1) 71%,
      rgba(183, 209, 229, 1) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(31, 129, 174, 1) 0%,
      rgba(183, 209, 229, 1) 71%,
      rgba(183, 209, 229, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(31, 129, 174, 1) 0%,
      rgba(31, 129, 174, 0.5) 71%,
      rgba(31, 129, 174, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f81ae', endColorstr='#b7d1e5',GradientType=1 );
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
@media (max-width: 767px) {
  /**/
  #block-az-front-block {
    margin-top: 25px;
  }
  /*slider text*/
  .slider-area .slider-image + .title-text h1,
  .slide__bg .internal-banner-section h1 {
    font-size: 24px;
    margin-bottom: 0;
  }
  .slider-area .slider-image + .title-text h3,
  .slide__bg .internal-banner-section p {
    font-size: 15px;
  }
  .slider-area .slider-image {
    position: relative;
  }
  .slider-area {
    padding: 0px 0;
  }
  .slider-area .container {
    padding: 0;
    width: 100%;
    height: 100%;
    display: block;
  }

  .slider-area .container .region-slider {
    width: 100%;
    height: 100%;
    display: block;
  }

  .slider-area .container .region-slider .az-logo {
    width: 100%;
    height: 100%;
    display: block;
  }

  .slider-area .container .region-slider .views-element-container {
    width: 100%;
    height: 100%;
    display: block;
  }

  .slider-area .container .region-slider .form-group {
    width: 100%;
    height: 100%;
    display: block;
  }

  .slider-area .container .region-slider .form-group .view.view-content-views {
  }

  .slider-area .container .region-slider .form-group .view.view-content-views {
    width: 100%;
    height: 100%;
    display: block;
  }

  .slider-area
    .container
    .region-slider
    .form-group
    .view.view-content-views
    .view-content {
    width: 100%;
    height: 100%;
    display: block;
  }

  .slider-area
    .container
    .region-slider
    .form-group
    .view.view-content-views
    .view-content
    > div {
    width: 100%;
    height: 100%;
    display: block;
  }

  .slider-image .field.field--name-body {
    padding: 40px 20px;
  }
  .slider-area .slider-image h3 {
    font-size: 13px;
    width: 60%;
  }
  .slider-area .slider-image h1 {
    width: 60%;
    font-size: 16px;
  }
  .logo {
    width: 200px;
  }
  .home-content .field__item ul {
    list-style: none;
  }
  .home-content .field__item ul li {
    padding: 0px 0px 0px 15px;
    position: relative;
  }
  .home-content .field__item ul li:before {
    width: 8px;
    height: 8px;
    background-color: #000;
    content: "";
    position: absolute;
    left: 0px;
    top: 8px;
    border-radius: 50%;
  }
  .list-group,
  .side-section ul {
    margin-right: 0;
    margin-left: 0;
  }
  .internal-page h1 {
    font-size: 22px;
  }
  .internal-page h2 {
    font-size: 20px;
  }

  table.plantable {
    width: 100%;
  }
}
@media (max-width: 567.98px) {
  /*froms button*/
  .form-actions .form-submit.webform-button--previous {
    padding: 9px 10px;
  }
  .webform-submission-form
    #edit-accttype
    .form-item.js-form-item.form-type-radio {
    width: 100%;
  }
  /*contact page*/
  .slide__bg {
    background-position: top center !important;
  }
  /*webfrom-pannel title*/
  .webform-submission-form
    #edit-step-2-page
    .form-item.panel-default
    > .panel-heading
    .panel-title {
    width: 70%;
  }
  .slider-area .slider-image + .title-text,
  .slide__bg .internal-banner-section {
    width: 100%;
    background: rgba(31, 129, 174, 1);
  }
  .slider-area .slider-image + .title-text h1,
  .slide__bg .internal-banner-section h1 {
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 8px;
  }
  .slider-area {
    height: auto;
  }
  .slider-image .field.field--name-body {
    padding: 90px 20px;
  }
  .slider-area .slider-image img {
    object-position: 80%;
  }
  .slider-area .slider-image + .title-text h3 {
    margin-bottom: 0;
  }
  .slider-area .slider-image + .title-text {
    padding-bottom: 10px;
  }
  /*request changes*/
  .webform-submission-form
    .form-item
    input[type="checkbox"].error
    + label.error,
  .webform-submission-form .form-item input[type="radio"].error + label.error {
    position: absolute;
    top: 25px;
    left: 0;
    padding: 0;
  }
  .webform-submission-form .form-item input[type="radio"].error + label.error {
    top: -10px;
  }
  .webform-submission-form .form-item .radio:nth-child(1) label.control-label {
    margin-top: 12px;
  }
  .webform-submission-form
    .form-item
    .checkbox:nth-child(3)
    label.control-label {
    margin-bottom: 30px;
  }
  .webform-submission-form .form-item .checkbox label.control-label,
  .webform-submission-form .form-item .radio label.control-label {
    width: 100%;
  }
}
@media (max-device-width: 767px) and (orientation: portrait) {
  .right-section {
    float: left;
    width: 100%;
  }
  .search-section {
    width: 100%;
  }
  .tab-area {
    float: none;
    margin: 0 auto;
    display: block;
    text-align: center;
    clear: both;
  }
  .tab-area .field--type-text-with-summary {
    display: inline-block;
    vertical-align: top;
  }
}
@media (max-device-width: 767px) and (orientation: landscape) {
  .slider-image .field.field--name-body {
    padding: 90px 20px;
  }
  .slider-image .field.field--name-body {
    min-height: 300px;
    object-position: 68%;
  }
}

@media (min-width: 980px) and (max-width: 992px) {
  .npa.navbar-default .navbar-nav > li > a {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 980px) {
  .npa.navbar-default .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }
  .npa.navbar-default ul li.cus-members:hover > ul,
  .npa.navbar-default ul li.cus-members.submenu-open > ul {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -2px;
    padding: 0;
    width: 500px;
  }
  .npa.navbar-default ul li.cus-members > ul > li {
    margin: 0;
    width: 50%;
  }
  .npa.navbar-default ul li.cus-members > ul > li > a {
    height: 100%;
  }
  .npa.navbar-default ul li.cus-members > ul > li:nth-child(2n) {
    border-left: 1px solid #dadada;
  }
  .npa.navbar-default ul li.cus-members > ul > li:nth-last-child(2) {
    border-bottom: none;
  }
  .npa.navbar-default ul li.cus-members > ul > li:first-child,
  .npa.navbar-default ul li.cus-members > ul > li:nth-child(2) {
    padding-top: 5px;
  }
  .npa.navbar-default ul li.cus-members > ul > li:last-child,
  .npa.navbar-default ul li.cus-members > ul > li:nth-last-child(2) {
    padding-bottom: 5px;
  }
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}

.error {
  color: red;
}

.btn-link.btn-anchor {
  outline: none !important;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

.accordionSummary {
  flexdirection: "row-reverse";
}

.button:disabled {
  color: black;
}
