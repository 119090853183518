#backtotop {
  background-image: url(../backtotop.png) no-repeat center center;
  bottom: 20px;
  cursor: pointer;
  display: none;
  height: 70px;
  position: fixed;
  right: 20px;
  text-indent: -9999px;
  width: 70px;
  z-index: 1;
}

#backtotop:hover {
  opacity: 0.8;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  #backtotop {
    background-image: url(../backtotop2x.png) no-repeat center center;
    background-size: 70px 70px;
  }
}

@media print {
  #backtotop {
    visibility: hidden;
    ckground-size: 70px 70px;
    ckground-size: 70px 70px;
  }
}
