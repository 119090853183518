/*body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, p, pre, code, blockquote {
	margin:0;
	padding:0;
	border-width:0;
}*/
td, th {
	border-style:solid;
	border-width:1px;
}
table {
	border-collapse:collapse;
}
/*li {
	display:block;
}*/
body {
	/*font-size:12px;*/
	-epub-hyphens:auto;
}
table.No-Table-Style {
	border-color:#000000;
	border-style:solid;
	border-width:1px;
	margin-bottom:-4px;
	margin-top:4px;
}
p.No-Paragraph-Style {
	color:#000000;
	font-family:"Minion Pro", serif;
	font-size:12px;
	font-style:normal;
	font-variant:normal;
	font-weight:normal;
	line-height:1.2;
	margin-bottom:0px;
	margin-left:0px;
	margin-right:0px;
	margin-top:0px;
	text-align:left;
	text-decoration:none;
	text-indent:0px;
}
p.Basic-Paragraph {
	color:#000000;
	font-family:"Minion Pro", serif;
	font-size:12px;
	font-style:normal;
	font-variant:normal;
	font-weight:normal;
	line-height:1.2;
	margin-bottom:0px;
	margin-left:0px;
	margin-right:0px;
	margin-top:0px;
	text-align:left;
	text-decoration:none;
	text-indent:0px;
}
p.body {
	-epub-hyphens:none;
	color:#000000;
	font-family:"Univers LT Std 55", sans-serif;
	font-size:12px;
	font-style:normal;
	font-variant:normal;
	font-weight:normal;
	line-height:1.25;
	margin-bottom:8px;
	margin-left:0px;
	margin-right:0px;
	margin-top:0px;
	text-align:left;
	text-decoration:none;
	text-indent:0px;
}
p.headline {
	-epub-hyphens:none;
	color:#ffffff;
	font-family:"Univers LT Std 45 Light", sans-serif;
	font-size:26px;
	font-style:normal;
	font-variant:normal;
	font-weight:bold;
	line-height:1.154;
	margin-bottom:17px;
	margin-left:0px;
	margin-right:0px;
	margin-top:0px;
	text-align:left;
	text-decoration:none;
	text-indent:0px;
}
p.table-head {
	-epub-hyphens:none;
	color:#ffffff;
	font-family:"Univers LT Std 47 Cn Lt", sans-serif;
	font-size:12px;
	font-style:normal;
	font-variant:normal;
	font-weight:bold;
	line-height:1.091;
	margin-bottom:0px;
	margin-left:0px;
	margin-right:0px;
	margin-top:0px;
	text-align:center;
	text-decoration:none;
	text-indent:0px;
}
p.table-body {
	-epub-hyphens:none;
	color:#000000;
	font-family:"Univers LT Std 57 Cn", sans-serif;
	font-size:12px;
	font-style:normal;
	font-variant:normal;
	font-weight:normal;
	line-height:1.136;
	margin-bottom:1px;
	margin-left:0px;
	margin-right:0px;
	margin-top:1px;
	text-align:left;
	text-decoration:none;
	text-indent:0px;
}
span.table-BOLD {
	font-style:normal;
	font-weight:bold;
}
span.superscript {
	vertical-align:super;
}
td.cell-style-override-1 {
	background-color:#2d3e67;
	padding-bottom:5px;
	padding-left:5px;
	padding-right:5px;
	padding-top:5px;
}
td.cell-style-override-2 {
	padding-bottom:5px;
	padding-left:5px;
	padding-right:5px;
	padding-top:5px;
}
td.cell-style-override-3 {
	padding-bottom:0px;
	padding-left:0px;
	padding-right:0px;
	padding-top:5px;
}
p.para-style-override-1 {
	text-align:left;
}
p.para-style-override-2 {
	text-align:center;
}
p.para-style-override-3 {
	font-family:"Univers LT Std 55", sans-serif;
	font-style:normal;
	font-weight:normal;
}
p.para-style-override-4 {
	font-family:Arial, sans-serif;
	font-size:11px;
	font-style:normal;
	font-weight:normal;
}
p.para-style-override-5 {
	color:#ffffff;
	font-family:"Univers LT Std 45 Light", sans-serif;
	font-size:32px;
	font-style:normal;
	font-weight:bold;
	text-align:center;
}
span.char-style-override-1 {
	color:#ffffff;
	font-family:"Univers LT Std 55", sans-serif;
	font-size:14px;
	font-style:normal;
	font-weight:normal;
}
span.char-style-override-2 {
	color:#ffffff;
	font-family:"Univers LT Std 55", sans-serif;
	font-size:12px;
	font-style:normal;
	font-weight:normal;
	vertical-align:super;
}
span.char-style-override-3 {
	font-style:normal;
	font-weight:bold;
}
span.char-style-override-4 {
	font-size:12px;
}
/*img.frame-1 {
	height:139px;
	width:629px;
}*/
div.frame-2 {
	margin:0px auto 0px auto;
	text-align:center;
}
col.Row-Column-3 {
	width:143px;
}
col.Row-Column-4 {
	width:98px;
}
tr.Row-Column-5 {
	min-height:18px;
}
tr.Row-Column-6 {
	min-height:32px;
}
tr.Row-Column-7 {
	min-height:54px;
}
tr.Row-Column-8 {
	min-height:69px;
}
tr.Row-Column-9 {
	min-height:103px;
}
tr.Row-Column-10 {
	min-height:66px;
}
tr.Row-Column-11 {
	min-height:57px;
}