input.csdateinput[type="date"]::-webkit-inner-spin-button,
input.csdateinput[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}


input.csdateinput[type="date"] { 
    text-transform: uppercase;
}
::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: none;
}
::placeholder { /* Recent browsers */
    text-transform: none;
}